/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import {
  Overlay,
  ModalContainer,
  Title,
  Question,
  OptionButton,
  ButtonContainer,
  CancelButton,
  SubmitButton,
  OptionsContainer,
} from './style';

import { useToast } from '../../../hooks/toast';

// import handler from '../../services/send-email';
import { createFeedback } from '../../../services/api';

const ReportProblemModal = ({ show, itemData, onClose }): any => {
  const { addToast } = useToast();

  if (!show) return null;
  const currentUrl = window.location.href;
  // const itemId = currentUrl.split('itemId=')[1].split('&')[0];
  // const studentId = currentUrl.split('studentId=')[1];

  // http://localhost:3000/seller/rented-unit/item/3a68533f-10b0-4519-9e45-5c2eb52e64de

  let itemId = currentUrl.split('/').pop();
  if (!itemId) {
    itemId = '3a68533f-10b0-4519-9e45-5c2eb52e64de';
  }
  const studentId = null;

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [description, setDescription] = useState('');

  const options = [
    'Vídeo não carrega',
    'Vídeo em baixa qualidade',
    'Divergência de conteúdo texto x vídeos',
    'Imagens ou fluxos com erros',
    'Botões não funcionam',
    'Problemas de carregamento',
    'Conteúdo ofensivo',
    'Erros nas respostas das atividades',
    'Erros nas fórmulas ou códigos',
    'Erros ortográficos',
  ];

  // Função para lidar com a seleção das opções
  const handleOptionClick = (option: any): void => {
    setSelectedOption(selectedOption === option ? null : option);
  };

  const handleOverlayClick = (e: any): void => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <Overlay onClick={handleOverlayClick}>
      <ModalContainer>
        <Title>Relatar problema</Title>
        <Question>O que fez você se sentir assim?</Question>
        <OptionsContainer>
          {' '}
          {/* Usando o contêiner */}
          {options.map((option, index) => (
            <OptionButton
              key={option}
              isSelected={selectedOption === option}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </OptionButton>
          ))}
        </OptionsContainer>
        <div>
          <textarea
            placeholder="Descreva o problema"
            value={description}
            onChange={e => setDescription(e.target.value)}
            style={{ width: '50%', height: '50', padding: '10px' }}
          />
        </div>

        <ButtonContainer>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
          <SubmitButton
            isSelected={!!selectedOption}
            description={description}
            onClick={async () => {
              if (selectedOption && description) {
                const data = {
                  itemFeedback: {
                    name: selectedOption,
                    unit_id: itemId,
                    unit_name: itemData.title,
                    error_type: selectedOption,
                    error_description: description,
                    student_id: studentId,
                  },
                };
                try {
                  console.log(data);
                  await createFeedback(data);

                  // show a flash message with the result
                  addToast({
                    type: 'success',
                    title: 'Sucesso!',
                    description:
                      'Problema reportado com sucesso! Obrigado por nos ajudar a melhorar!',
                  });

                  onClose();
                } catch (error) {
                  addToast({
                    type: 'error',
                    title: 'Erro!',
                    description:
                      'Erro ao reportar o problema. Tente novamente mais tarde.',
                  });
                }
              }
            }}
          >
            Enviar
          </SubmitButton>
        </ButtonContainer>
      </ModalContainer>
    </Overlay>
  );
};

export default ReportProblemModal;
