import styled from 'styled-components';

interface IOptionButtonProps {
  isSelected: boolean;
  description?: string;
}

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 320px) {
    padding: 10px;
  }
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 13px;
  border-radius: 8px;
  width: 61%;
  max-width: 80%;
  text-align: center;

  @media (max-width: 1280px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    padding: 7px;
  }

  @media (max-width: 320px) {
    padding: 5px;
  }
`;

export const Title = styled.h1`
  color: #4a3e91;
  font-size: 1.6em;
`;

export const Question = styled.p`
  color: #4a3e91;
  margin-top: 1em;
  font-size: 1em;
`;

export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 15px;

  @media (max-width: 1285px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }

  @media (max-width: 840px) {
    grid-template-columns: 1fr;
    overflow-x: auto;
  }

  @media (max-width: 320px) {
    padding: 10px;
  }
`;

export const OptionButton = styled.div<IOptionButtonProps>`
  border: 1px solid #4a3e91;
  color: #4a3e91;
  padding: 8px 12px;
  border-radius: 20px;
  background-color: ${({ isSelected }) => (isSelected ? '#4a3e91' : 'white')};
  color: ${({ isSelected }) => (isSelected ? 'white' : '#4a3e91')};
  cursor: pointer;
  white-space: nowrap; /* Evita que o texto quebre em várias linhas */

  &:hover {
    background-color: #e1e2ec;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

export const CancelButton = styled.div`
  background-color: white;
  color: #4a3e91;
  padding: 10px 20px;
  border: 1px solid #4a3e91;
  border-radius: 5px;
  cursor: pointer;
`;

export const SubmitButton = styled.div<IOptionButtonProps>`
  background-color: ${({ isSelected, description }) =>
    isSelected && description ? '#4a3e91' : '#ccc'};
  color: ${({ isSelected, description }) =>
    isSelected && description ? 'white' : '#4a3e91'};
  padding: 10px 20px 0px 20px;
  border: none;
  border-radius: 5px;
  cursor: ${({ isSelected, description }) =>
    isSelected && description ? 'pointer' : 'not-allowed'};
`;
