import styled from 'styled-components';
import { Form } from '@unform/web';

interface IButtonConfirmProps {
  status: string; // ou 'open' | 'closed' se os valores forem fixos
}

export const Container = styled.div`
  padding: 0 20px 40px;

  table {
    width: 100%;
    border-spacing: 0;
    margin-top: 24px;
  }

  table th {
    font-size: 22px;
    text-align: left;
    font-weight: 400;
    background: #f1f1f1;

    padding: 10px;

    & + th {
      border-left: 1px solid #ddd;
    }
  }

  table td {
    font-size: 16px;
    padding: 10px;
  }

  table tr:nth-child(even) td {
    background: #f5f5f5;
  }

  table th:last-child,
  table td:last-child {
    text-align: right;
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;

  margin-top: 24px;

  button {
    display: flex;
    align-items: center;
    height: 45px;

    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0 16px;
    background: #e1e1e1;
    cursor: pointer;

    &:first-child {
      color: #fff;
      border-color: #7284ff;
      background: #7284ff;
    }

    &:hover,
    &:active {
      border-bottom-color: #ccc;
    }

    &[disabled] {
      cursor: default;
    }
  }

  button:active {
    transform: scale(0.98);
    top: 10px;
  }

  svg {
    margin-right: 8px;
  }
`;

export const FiltersWrapper = styled(Form)`
  display: flex;
  align-items: end;
  gap: 8px;
`;

export const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  color: #333;
  font-size: 22px;
  background: #f5f5f5;
  padding: 50px 0;
  border-radius: 0 0 8px 8px;
`;

export const Wrapper = styled.div`
  display: flex;

  color: #666;
  margin-top: 24px;
  padding: 12px 32px;

  section {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 100%;
      padding: 0 42px;
    }

    & + section div {
      border-left: 1px solid #efefef;
    }
  }

  strong {
    display: block;

    font-size: 52px;
    font-weight: 700;
  }
`;

export const ContainerCircle = styled.div`
  position: relative;
  display: inline-block;

  margin-right: 24px;

  svg circle:nth-child(2) {
    animation: circle-chart-fill 2s reverse;
    transform: rotate(-87deg);
    transform-origin: center;
  }

  svg + svg {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  @keyframes circle-chart-fill {
    to {
      stroke-dasharray: 0 100;
    }
  }
`;

export const ButtonConfirm = styled.button<IButtonConfirmProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 1px solid transparent;
  background: ${props => {
    if (props.status === 'resolved') return 'rgb(0, 102, 255)';
    if (props.status === 'closed') return 'rgb(0, 102, 255)';
    return 'rgb(177, 177, 177)';
  }};
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;

  &:hover,
  &:active {
    border-bottom-color: #ccc;
  }

  &[disabled] {
    cursor: default;
  }

  svg {
    margin-right: 8px;
  }
`;
